<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <b-form>
        <div>
          <h1 class="mr-sm-4 header-tablepage mb-3">Point</h1>
        </div>
        <!-- <div class="title-tabs mt-3">Member Tier Info</div> -->
        <b-container class="no-gutters bg-white">
          <div class="py-3">
            <b-row>
              <b-col md="6" cols="12">
                <div class="main-label">Expire Conditions</div>
                <b-form-group v-slot="{ ariaDescribedby }">
                  <b-form-radio-group
                    id="radio-group-1"
                    v-model="selected"
                    :options="options"
                    :aria-describedby="ariaDescribedby"
                    name="radio-options"
                    @change="handleSelectType"
                  ></b-form-radio-group>
                </b-form-group>
              </b-col>
              <b-col md="6" cols="12">
                <!-- <template v-if="selected == 'End of Year'">
                  <b-row>
                    <template v-for="(items, index) of condition">
                      <b-col cols="12" md="6" :key="items.id + index">
                        <InputText
                          textFloat="Year"
                          :placeholder="'Ex.' + $moment().years()"
                          type="number"
                          name="year-conditon"
                          isRequired
                          :oninput="'javascript: if (this.value.length > 4) this.value = this.value.slice(0,4);'"
                          v-model="items.condition"
                          :v="$v.condition.$each[index].condition"
                          :isValidate="
                            $v.condition.$each[index].condition.$error
                          "
                        />
                      </b-col>
                      <b-col cols="12" md="6" :key="items.id + index">
                        <InputText
                          textFloat="Expire Period"
                          placeholder="Expire Period (Days) Ex. 1"
                          type="number"
                          name="expire-condition"
                          isRequired
                          :oninput="'javascript: if (this.value.length > 3) this.value = this.value.slice(0,3);'"
                          v-model="items.expire_period_condtion"
                          :v="$v.condition.$each[index].expire_period_condtion"
                          :isValidate="
                            $v.condition.$each[index].expire_period_condtion
                              .$error
                          "
                        />
                      </b-col>
                    </template>
                  </b-row>
                  <div class="cursor-pointer" @click="addYear">
                    <font-awesome-icon icon="plus-square" class="mr-2" /> Add
                    Year Period
                  </div>
                </template> -->
                <template>
                  <InputText
                    textFloat="Expire Period"
                    placeholder="Expire Period (Days) Ex. 1"
                    type="number"
                    name="expire_period"
                    isRequired
                    v-model="expire"
                    :v="$v.expire"
                    :disabled="selected == 'No Expire'"
                    :isValidate="$v.expire.$error"
                  />
                </template>
              </b-col>
            </b-row>
          </div>
        </b-container>

        <FooterAction @submit="submit(0)" routePath="" />
      </b-form>
    </div>
  </div>
</template>

<script>
import OtherLoading from "@/components/loading/OtherLoading";
import { required, requiredIf, minLength } from "vuelidate/lib/validators";
import minValue from "vuelidate/lib/validators/minValue";
// import requiredIf from 'vuelidate/lib/validators/requiredIf';
export default {
  name: "BannerDetails",
  components: {
    OtherLoading,
  },
  data() {
    return {
      selected: "",
      options: [],
      form: [],
      expire: "",
      condition: [],
      id: 0,
      isLoading: false,
    };
  },
  validations: {
    expire: {
      required,
    },
  },
  created: async function () {
    this.getData();
  },
  methods: {
    async getData() {
      try {
        this.isLoading = true;
        const result = await this.axios("/setting/get_point_setting");

        let select = result.data.detail.find((el) => el.is_entry_point == 1);
        this.form = result.data.detail;
        this.id = select.id;
        this.options = result.data.detail.map((el) => el.name);
        this.selected = select.name;
        this.condition = select.condition;
        this.expire = select.expire_period;
        this.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    },
    async submit() {
      let payload = {
        id: this.id,
        is_entry_point: 1,
        name: this.selected,
        expire_period: this.expire,
        condition: [],
      };

      // this.$v.condition.$touch();
      this.$v.expire.$touch();
      if (this.$v.expire.$error) return;
      this.$bus.$emit("showLoading");

      const result = await this.axios.put(
        "/setting/update_point_setting",
        payload
      );
      this.getData();
      this.$bus.$emit("hideLoading");
      if (result.data.detail == 1) this.successAlert();
      else this.errorAlert(result.data.message);
    },
    handleSelectType(val) {
      let { condition, expire_period, id } = this.form.find(
        (el) => el.name == val
      );
      this.id = id;
      if (condition) {
        this.expire = 0;
        if (condition.length > 0)
          this.condition = condition.map((el) => {
            return { ...el, condition: Number(el.condition) };
          });
        else
          this.condition = [
            {
              id: id || 0,
              point_setting_id: id,
              condition: "",
              expire_period_condtion: "",
            },
          ];
      } else {
        this.expire = expire_period;
        this.condition = null;
      }
    },
    addYear() {
      this.condition.push({
        id: 0,
        point_setting_id: this.id,
        condition: "",
        expire_period_condtion: "",
      });
    },
  },
};
</script>

<style scoped>
.form-group {
  margin-bottom: 0;
  margin-top: 0.75rem;
}
</style>
